<template>
  <div>
    <transition name="fade">
      <b-card
          v-if="Object.entries(hrIndicator).length !== 0"
      >
        <b-row>

          <b-col
              cols="12"
              md="6"
              offset-md="3"
          >

            <donut
                @click.native.stop=""
                :labels="donutLabels"
                :values="donutValues"
                :total="hrIndicator.totalTime"
                :isHours="true"
                :isCurrencies="false"
            />
          </b-col>

        </b-row>
        <b-row>
          <b-col
              cols="12"
              v-for="(workflowByPercent, index) in workflowsByPercent"
              class="mt-1"
          >
            <b-row>
              <b-col cols="4">
                {{ workflowByPercent.workflow }}
              </b-col>
              <b-col
                  cols="6"
                  class="my-auto"
              >
                <div class="d-flex">
                  <b-progress
                      :key="index"
                      :value="(workflowByPercent.percent * 100)"
                      :id="'popover-'+index"
                      class="w-100 my-auto"
                  />
                  <div class="text-nowrap my-auto ml-2">
                    {{ percent((workflowByPercent.percent * 100), 0) }}
                  </div>

                </div>
              </b-col>
              <b-col
                  cols="2"
                  class="text-right my-auto"
              >
                {{ Math.round(workflowByPercent.time) + ' ' + $tc('hour', workflowByPercent.time) }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>


      </b-card>
    </transition>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, percent }  from '../../../utils/filter'

import useAPI from '../../../utils/useAPI'
import Donut  from '../../chart/Donut'

export default {
  components: {
    Donut
  },
  props: {
    employee: {},
    start: {},
    end: {},
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { hrIndicator } = useAPI()

    const donutLabels = computed(() => {
      let labels = []

      for (const item in hrIndicator.value.times) {
        labels.push(item)
      }

      return labels
    })

    const donutValues = computed(() => {
      let values = []

      for (const item in hrIndicator.value.times) {
        values.push(hrIndicator.value.times[item])
      }

      return values
    })

    const workflowsByPercent = computed(() => {
      let workflowsByPercent = []

      for (const item in hrIndicator.value.times) {
        let time = hrIndicator.value.times[item]

        workflowsByPercent.push({
          workflow: item,
          time: time,
          percent: hrIndicator.value.total == 0 ? 0 : (parseFloat((time / hrIndicator.value.total).toFixed(2)))
        })
      }

      return workflowsByPercent
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      percent,

      // Data

      // Computed
      hrIndicator,
      donutLabels,
      donutValues,
      workflowsByPercent,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>