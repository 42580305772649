<template>
  <b-row>
    <b-col md="2">
      <filters-card
          :employee.sync="selectedEmployee"
          :start.sync="start"
          :end.sync="end"
      />
    </b-col>

    <b-col md="10">
      <time-by-workflow />
    </b-col>
  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import {getUserData} from '../../../utils/utils'

import useAPI from '../../../utils/useAPI'
import moment from 'moment'
import store from '../../../store'
import FiltersCard from './components/_filters'
import TimeByWorkflow from '../../../components/indicators/humanRessources/TimeByWorkflow'

export default {
  components: {
    FiltersCard,
    TimeByWorkflow
  },
  props: {},
  setup () {
    const {fetchAutocompleteEmployees, fetchHRIndicator} =useAPI()
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const selectedEmployee = ref(null)
    const start = ref(moment('2023-09-01').format('YYYY-MM-DD'))
    const end = ref(moment().format('YYYY-MM-DD'))

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    // const employees = computed(() => {
    //   return store.getters['user/getEmployees']
    // })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(selectedEmployee, val => {
      fetchHRIndicator(val, {start: start.value, end: end.value})
    })
    watch(start, val => {
      fetchHRIndicator(selectedEmployee.value, {start: val, end: end.value})
    })
    watch(end, val => {
      fetchHRIndicator(selectedEmployee.value, {start: start.value, end: val})
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchAutocompleteEmployees()
    selectedEmployee.value = getUserData()

    return {
      // Components

      // Data
      selectedEmployee,
      start,
      end,

      // Computed
      // employees,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>